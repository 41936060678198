var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { default as Slider } from "react-slick";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { isDeviceResOrHigher, isDeviceResOrLower, useDeviceType, } from "@hooks/useDeviceType";
import { useTracking } from "@hooks/useTracking";
import { Modal } from "@components/Modal/Modal";
import { FormContainer } from "@components/Block/Forms/FormContainer";
import { CardOverlay } from "./CardOverlay";
import { brandColors } from "@basics/brand-colors";
var modalStyles = createUseStyles({
    container: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    row: {
        height: function (_a) {
            var isFormMobileOpen = _a.isFormMobileOpen;
            return (isFormMobileOpen ? "100%" : "auto");
        },
        width: "100%",
    },
    cardCarousel: __assign(__assign({}, onBreakpoint("md", {
        width: "calc(100% - 118px)",
        margin: "0 auto",
        top: "50%",
        transform: "translateY(-50%)",
    })), { "& .slick-track": {
            display: "flex",
        }, "& .slick-slide": {
            height: "auto",
            padding: "0 5px",
            "& > div": {
                height: "100%",
            },
        }, "& .slick-arrow": {
            width: 48,
            height: 48,
            "&:before": {
                color: brandColors.white,
                fontSize: "3rem",
            },
            border: "1px solid transparent",
            "&:focus": {
                border: "1px solid ".concat(brandColors.white),
                borderRadius: "5px",
            },
        }, "& .slick-prev": {
            left: -60,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f053"',
            },
        }, "& .slick-next": {
            right: -60,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f054"',
            },
        }, "& .slick-dots": {
            bottom: -40,
            "& button:before, & .slick-active button:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f111"',
                fontWeight: 900,
                fontSize: "1rem",
                color: brandColors.white,
            },
            "& button": {
                border: "1px solid transparent",
                "&:focus": {
                    border: "1px solid ".concat(brandColors.white),
                    borderRadius: "5px",
                },
            },
        } }),
    form: __assign({ background: brandColors.white, padding: "".concat(spacings.l, " ").concat(spacings.s.divide(2), " ").concat(spacings.l, " ").concat(spacings.s), borderRadius: "10px", "& > *": {
            height: "100%",
            overflowY: "auto",
            paddingRight: rem(spacings.s.divide(2)),
        }, "& .Form__Element:first-child": {
            marginTop: 0,
            "& h3": {
                marginBottom: 0,
            },
        } }, onBreakpoint("md", {
        height: "90vh",
        padding: "".concat(spacings.xl, " ").concat(spacings.xl.divide(2), " ").concat(spacings.xl, " ").concat(spacings.xl),
        "& > *": {
            paddingRight: rem(spacings.xl.divide(2)),
        },
    })),
    formModal: __assign(__assign({}, onBreakpoint("sm", {
        paddingTop: rem(spacings.xxl),
        alignItems: "baseline",
    })), onBreakpoint("md", {
        paddingTop: 0,
        alignItems: "center",
    })),
    formMobileModal: {
        paddingTop: rem(spacings.xxl),
        alignItems: "baseline",
    },
});
export var ContactListOverlay = function (props) {
    var _a, _b, _c;
    var _d = useContext(GlobalContentStoreContext), getStartPageData = _d.getStartPageData, getImmediateStartPageData = _d.getImmediateStartPageData;
    var _e = useState(getImmediateStartPageData()), startPageData = _e[0], setStartPageData = _e[1];
    var _f = useState(false), isFormMobileOpen = _f[0], setFormMobileOpen = _f[1];
    var tracking = useTracking();
    var styles = modalStyles({ isFormMobileOpen: isFormMobileOpen });
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, [getStartPageData, startPageData]);
    var _g = useState(null), slider = _g[0], setSlider = _g[1];
    var deviceType = useDeviceType();
    var tabletAtLeast = isDeviceResOrHigher(deviceType, "tablet");
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var _h = useState(props.initialSlide), currentContactIndex = _h[0], setCurrentContactIndex = _h[1];
    useEffect(function () {
        setFormMobileOpen(function (s) { return s && isMobile; });
    }, [isMobile]);
    useEffect(function () {
        setCurrentContactIndex(props.initialSlide);
    }, [props.initialSlide]);
    var carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: tabletAtLeast,
        initialSlide: props.initialSlide,
        beforeChange: function (_, newIndex) {
            return setCurrentContactIndex(newIndex);
        },
    };
    if (!startPageData)
        return null;
    var currentContact = (_a = props.contacts) === null || _a === void 0 ? void 0 : _a[currentContactIndex];
    return (React.createElement(Modal, { modalOpened: props.modalOpened, closeModal: props.closeModal, onLeftArrow: function () { return slider === null || slider === void 0 ? void 0 : slider.slickPrev(); }, onRightArrow: function () { return slider === null || slider === void 0 ? void 0 : slider.slickNext(); }, className: cn(styles.formModal, isFormMobileOpen && styles.formMobileModal), allowMobile: true },
        React.createElement(Container, { className: styles.container },
            React.createElement(Row, { className: styles.row },
                !isFormMobileOpen && (React.createElement(Col, { sm: 8, smStart: 2, md: 6, mdStart: null, lg: 5, style: { width: "100%" /* due to FF */ } },
                    React.createElement(Slider, __assign({ ref: function (c) { return setSlider(c); } }, carouselSettings, { className: styles.cardCarousel }), (_b = props.contacts) === null || _b === void 0 ? void 0 : _b.map(function (i, idx) { return (React.createElement(CardOverlay, __assign({}, i, { key: idx, openFormMobile: function () { return setFormMobileOpen(true); }, startPageData: startPageData }))); })))),
                (!isMobile || (isMobile && isFormMobileOpen)) && (React.createElement(Col, { md: 5, lg: 6, className: styles.form }, ((_c = startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactOverlayWebform) === null || _c === void 0 ? void 0 : _c.expandedValue) && (React.createElement(FormContainer, { data: startPageData.contactOverlayWebform
                        .expandedValue, emailValue: (currentContact === null || currentContact === void 0 ? void 0 : currentContact.email.value) || "", trackingFunction: function () {
                        return tracking.event("Contacts", "Form submission", (currentContact === null || currentContact === void 0 ? void 0 : currentContact.email.value) || "");
                    } }))))))));
};

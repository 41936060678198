import { useState, useLayoutEffect } from "react";
import { isCypressTest } from "@basics/isCypressTest";
import { useDebounce } from "./useDebounce";
export function useWindowWidth() {
    var _a = useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    var debounce = useDebounce().debounce;
    useLayoutEffect(function () {
        function updateSize() {
            var innerWidth = window.innerWidth;
            if (innerWidth === width)
                return;
            isCypressTest()
                ? setWidth(innerWidth)
                : debounce(function () { return setWidth(innerWidth); });
        }
        window.addEventListener("resize", updateSize);
        return function () { return window.removeEventListener("resize", updateSize); };
    }, [debounce, width]);
    return width;
}

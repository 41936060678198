var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { createUseStyles } from "react-jss";
import { MODALS_ROOT_ID, NO_SCROLLING_CLASS } from "@/src/App";
import { animations } from "@basics/animations";
import { onBreakpoint } from "@basics/layout";
import { typography } from "@basics/typography";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { brandColors } from "@basics/brand-colors";
var CLOSE_BUTTON_SIZE = 50;
var CLOSE_BUTTON_SPACING = 12;
var modalStyles = createUseStyles(__assign(__assign(__assign({}, animations.simpleFadeIn), animations.simpleFadeOut), { modal: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 101,
        backgroundColor: "rgba(0, 0, 0, 0.95)",
        "@supports (backdrop-filter: blur(5px))": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        overflow: "auto",
        backdropFilter: "blur(5px)",
    }, modalRevealAnimation: function (_a) {
        var isOpened = _a.isOpened;
        return ({
            animation: isOpened
                ? "$simpleFadeIn 0.3s ease"
                : "$simpleFadeOut 0.3s ease",
        });
    }, closeButton: {
        position: "absolute",
        top: CLOSE_BUTTON_SPACING,
        right: CLOSE_BUTTON_SPACING,
        cursor: "pointer",
        zIndex: 101,
        width: CLOSE_BUTTON_SIZE,
        height: CLOSE_BUTTON_SIZE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid transparent",
        font: "inherit",
        color: brandColors.white,
        textAlign: "inherit",
        background: "none",
        opacity: 0.85,
        transition: "opacity 0.3s ease",
        outline: "none",
        "&:hover": {
            opacity: 1,
            transition: "opacity 0.3s ease",
        },
        "&:focus": {
            border: "1px solid ".concat(brandColors.white),
            borderRadius: "5px",
        },
    }, closeIcon: __assign({}, FAIconStyles(__assign(__assign({ width: 32, height: 32 }, onBreakpoint("sm", {
        width: 55,
        height: 55,
    })), onBreakpoint("md", {
        width: 70,
        height: 70,
    })))), closeIconLabel: __assign({}, typography.visuallyHidden) }));
export function Modal(props) {
    var _a = useState(false), isModalRendered = _a[0], setIsModalRendered = _a[1];
    var modalWrapperRef = useRef(null);
    var root = document.getElementsByTagName("html")[0] || undefined;
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var styles = modalStyles({
        isOpened: props.modalOpened,
    });
    function isInputFocused() {
        var activeElement = document.activeElement;
        var inputs = ["input", "textarea"];
        return (activeElement &&
            inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1);
    }
    function onKeyDown(event) {
        if (event.keyCode === 27) {
            props.closeModal();
        }
        if (event.keyCode === 37 && props.onLeftArrow && !isInputFocused()) {
            props.onLeftArrow();
        }
        if (event.keyCode === 39 && props.onRightArrow && !isInputFocused()) {
            props.onRightArrow();
        }
    }
    useEffect(function () {
        document.addEventListener("keydown", onKeyDown, false);
        return function () { return document.removeEventListener("keydown", onKeyDown, false); };
    });
    useEffect(function () {
        if (!root)
            return;
        if (isModalRendered) {
            root.classList.add(NO_SCROLLING_CLASS);
        }
        else {
            root.classList.remove(NO_SCROLLING_CLASS);
        }
        return function () {
            root.classList.remove(NO_SCROLLING_CLASS);
        };
    }, [isModalRendered, root]);
    useEffect(function () {
        if (props.modalOpened && (!isMobile || props.allowMobile))
            setIsModalRendered(true);
    }, [isMobile, props.allowMobile, props.modalOpened]);
    var onAnimationEnd = function () {
        if (!props.modalOpened)
            setIsModalRendered(false);
    };
    var portalContainer = document.querySelector("#".concat(MODALS_ROOT_ID)) || undefined;
    return portalContainer
        ? createPortal(React.createElement(React.Fragment, null, isModalRendered && (React.createElement(FocusTrap, null,
            React.createElement("div", { ref: modalWrapperRef, className: cn(styles.modalRevealAnimation, props.className, styles.modal), onAnimationEnd: function () { return onAnimationEnd(); }, onClick: function (e) {
                    return e.target === modalWrapperRef.current && props.closeModal();
                }, "data-testid": "Modal" },
                React.createElement("button", { onClick: function () { return props.closeModal(); }, className: styles.closeButton },
                    React.createElement("span", { className: styles.closeIconLabel }, "Close"),
                    React.createElement(FAIcon, { icon: "times", className: styles.closeIcon })),
                props.children)))), portalContainer)
        : null;
}

import { RenderProperty } from "@/src/views/RenderProperty";
import { brandColors } from "@basics/brand-colors";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import React from "react";
import { createUseStyles } from "react-jss";
var CLOSE_BUTTON_SIZE = 50;
var CLOSE_BUTTON_SPACING = 12;
var useGenericBodyStyles = createUseStyles({
    container: {
        width: "97%",
        maxWidth: 830,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: brandColors.white,
        borderRadius: "10px",
        maxHeight: "calc(100% - ".concat((CLOSE_BUTTON_SIZE + CLOSE_BUTTON_SPACING * 2) * 2, "px)"),
        display: "flex",
        flexDirection: "column",
    },
    header: {
        padding: rem(spacings.m),
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        background: brandColors.ultraLightGrey,
    },
    heading: customTypography(typography.h4, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    content: {
        margin: "".concat(spacings.m),
        overflowY: "auto",
    },
});
export function GenericModalBodyContainer(props) {
    var styles = useGenericBodyStyles();
    return (React.createElement("article", { className: styles.container },
        React.createElement("header", { className: styles.header },
            React.createElement("h2", { className: styles.heading },
                React.createElement(RenderProperty, { value: props.heading }))),
        React.createElement("div", { className: styles.content }, props.children)));
}
